





















































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { useActions, useGetters } from "vuex-composition-helpers";
import Catalogue from "@/enumerators/catalogue";
import CompareCell from "./CompareCell.vue";
import Benefits from "./Benefits.vue";
import format from "../services/format";
import QuotationCell from "@/components/QuotationCell.vue";
import QuotationHeader from "@/components/QuotationHeader.vue";
import staticText from "../enumerators/export";
import API from "@/plugins/axios";
import session from "@/services/session";
import StrapiServices from "../enumerators/strapiServices";
import { dragscroll } from "vue-dragscroll";
export default defineComponent({
  props: ["step"],
  directives: {
    dragscroll,
  },
  components: {
    CompareCell,
    Benefits,
    QuotationCell,
    QuotationHeader,
  },
  setup(props) {
    const {
      SET_STEP,
      SET_QUOTATION,
      SET_HIDDENROWS,
      SET_CREATE_QUOTATION,
    } = useActions([
      "SET_STEP",
      "SET_QUOTATION",
      "SET_HIDDENROWS",
      "SET_CREATE_QUOTATION",
    ]);
    const {
      quotation,
      hiddenRows,
      benefitsNames,
      searchSelection,
      clientSelection,
      createQuotation,
      user,
    } = useGetters([
      "quotation",
      "hiddenRows",
      "benefitsNames",
      "searchSelection",
      "clientSelection",
      "createQuotation",
      "user",
    ]);
    const state = reactive({
      benefitsName: {
        inPatient: [],
        outPatient: [],
      },
      policy: {
        data: [],
      },
    });
    let fab = ref(false);
    onMounted(async () => {
      if (createQuotation.value) {
        //create new quotation number
        const clientInfo: Array<{
          name: string;
          age: number;
          gender?: number;
          pipedriveId?: number;
        }> = [];
        if (!searchSelection.value.isOrganization)
          clientInfo.push({
            name: clientSelection.value.name,
            age: clientSelection.value.age,
          });
        else {
          searchSelection.value.organizationMembers.map((mem) => {
            clientInfo.push({
              name: mem.name,
              age: mem.age,
              gender: mem.gender,
              pipedriveId: mem.id,
            });
          });
        }
        const entry = {
          searchResult: quotation.value.res,
          client: clientInfo,
          user: user?.value.pipedriveId,
        };

        // create entry for quotation
        // const quotationId = await API.strapi(session.getStoredToken())
        API.strapi(session.getStoredToken())
          .post(StrapiServices.CREATE_QUOTATION_NUMBER, entry)
          .catch((err) => {
            console.log(err);
            if (err.response.statusText === staticText.UNAUTHORIZED)
              session.logout();
          })
          .then((quotationNumRes: any) => {
            SET_CREATE_QUOTATION(false);
            SET_QUOTATION({
              id: quotationNumRes?.data,
              res: quotation.value.res,
            });
          });
        // if (quotationId) {
        //   SET_QUOTATION({
        //     id: quotationId?.data,
        //     res: res.data,
        //   });
        // }
      }

      const benef = benefitsNames.value;
      state.benefitsName.inPatient = format.sortByOrder(
        format.filterByType(benef.data, Catalogue.PatientType.IN_PATIENT)
      );
      state.benefitsName.outPatient = format.sortByOrder(
        format.filterByType(benef.data, Catalogue.PatientType.OUT_PATIENT)
      );

      state.policy.data = getSearchedPolicy();
      // console.log("state.policy.data: ", state.policy.data);

      SET_STEP(props.step);
    });

    // console.log("state.policy: ", state.policy);
    const plans = computed(() => format.getHeaderPlans(state.policy.data));

    const planDetails = computed(() =>
      format.getHeaderPlansDetails(state.policy.data)
    );
    const getSearchedPolicy = () =>
      JSON.parse(JSON.stringify(quotation?.value))?.res;

    const coverage_include = computed(() =>
      format.coverageInclude(state.policy.data)
    );
    const coverage_exclude = computed(() =>
      format.coverageExclude(state.policy.data)
    );
    const annualLimit = computed(() =>
      format.interpretAnnualLimit(state.policy.data)
    );
    const deductOption = computed(() =>
      format.interpretDeductOption(state.policy.data)
    );
    const coPayOption = computed(() =>
      format.interpretCopayOption(state.policy.data)
    );
    const annualDeduct = computed(() =>
      format.interpretAnnualDeduct(state.policy.data)
    );
    const annualPremium = computed(() =>
      format.interpretAnnualPremium(state.policy.data)
    );
    // const premiumList = computed(() =>
    //   format.isEmpty(premiums.value)
    //     ? format.getPremiumList(state.policy.data)
    //     : premiums.value
    // );
    const paymentSurcharge = computed(() =>
      format.interpretSurcharge(state.policy.data)
    );

    const showRow = (label) => {
      const index = hiddenRows.value.labels.indexOf(
        hiddenRows.value.labels.find((row) => row == label)
      );
      if (index > -1) {
        hiddenRows.value.labels.splice(index, 1);
      }
      SET_HIDDENROWS(hiddenRows.value);
    };

    const removePolicy = (index: number) => {
      state.policy.data.splice(index, 1);

      //update res in store
      const storeQuotation = JSON.parse(JSON.stringify(quotation?.value));
      storeQuotation.res = state.policy.data;
      SET_QUOTATION(storeQuotation);
    };

    let familyMembers;
    if (searchSelection.value.isOrganization) {
      familyMembers = searchSelection.value.organizationMembers;
    }

    return {
      state,
      coverage_include,
      coverage_exclude,
      getSearchedPolicy,
      format,
      plans,
      planDetails,
      staticText,
      annualLimit,
      removePolicy,
      deductOption,
      coPayOption,
      annualDeduct,
      annualPremium,
      paymentSurcharge,
      dragscroll,
      fab,
      hiddenRows,
      showRow,
      familyMembers,
    };
  },
});
