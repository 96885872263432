
















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useGetters } from "vuex-composition-helpers";
import LoginForm from "./views/Login.vue";
import userService from "./services/users";
import { useActions } from "vuex-composition-helpers";
import session from "./services/session";
import Header from "./components/Header.vue";
import "./common/font.scss";
export default defineComponent({
  name: "App",
  components: {
    LoginForm,
    Header,
  },

  setup() {
    const { SET_USER } = useActions(["SET_USER"]);
    const { user } = useGetters(["user"]);
    let loading = ref(true);
    const init = async () => {
      const user = await session.verifyToken();
      if (user) {
        SET_USER(userService.createUser(user.data));
      }
      // console.log(loading);
      loading.value = false;
    };
    onMounted(init);
    return { user, loading };
  },
});
