


































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import format from "../services/format";
import { useActions, useGetters } from "vuex-composition-helpers";
import staticText from "../enumerators/export";
import Discount from "../components/Discount.vue";
import NewPremium from "../components/NewPremium.vue";
import PremiumBreakdown from "../components/PremiumBreakdown.vue";
import PriceDropIcon from "../components/PriceDropIcon.vue";
import CheckMark from "../components/Checkmark.vue";
// import CheckMark from "../assets/checkmark.png";

export default defineComponent({
  props: [
    "plans",
    "color",
    "label",
    "bold",
    "benefit",
    "removeCol",
    "isInclude",
    "isSearch",
    "familyMembers",
  ],
  components: {
    Discount,
    PriceDropIcon,
    CheckMark,
    NewPremium,
    PremiumBreakdown,
  },
  setup(props) {
    const { SET_HIDDENROWS, SET_PREMIUMS } = useActions([
      "SET_HIDDENROWS",
      "SET_PREMIUMS",
    ]);
    const { hiddenRows, step, discount, premiums } = useGetters([
      "hiddenRows",
      "step",
      "discount",
      "premiums",
    ]);
    const getId = props.label.replace(/\s/g, "") + "-row";
    const getLabelId = props.label.replace(/\s/g, "") + "-label";
    const rowHeight = ref(null);
    const discountDialog = ref(false);

    onMounted(() => {
      // console.log("familyMembers: ", props.familyMembers);
      // if (props.plans) console.log("plans: ", props.plans);
      if (props.isSearch) {
        const labelH = document.getElementById(getLabelId)?.offsetHeight;
        const cellH = document.getElementById(getId)?.offsetHeight;
        rowHeight.value = cellH > labelH ? cellH : labelH;
      }
    });
    const isHidden = computed(() => {
      // console.log("isHidden | hiddenRows",hiddenRows)
      return hiddenRows.value.labels.find((row) => row == props.label)
        ? true
        : false;
    });
    const hideRow = (label) => {
      hiddenRows.value.labels.push(label);
      SET_HIDDENROWS(hiddenRows);
    };
    const updateDialog = (val, id, dialog) => {
      updatePremium(id, dialog, val);
    };
    const expandRow = () => {
      rowHeight.value = 60;
    };
    const getDiscount = (id) => {
      return premiums.value[id].discount;
    };
    const updatePremium = (id, key, val) => {
      SET_PREMIUMS(format.updatePremium(id, key, val, premiums));
    };
    const openBreakdownDialog = (id) =>
      (premiums.value[id].breakdownDialog = !premiums.value[id]
        .breakdownDialog);

    const getBreakdown = (familyPremiums) => {
      const breakdownList = [];
      familyPremiums.map((prem) => {
        const member = props.familyMembers.find(
          (mem) =>
            parseInt(mem.age) === parseInt(prem.age) &&
            parseInt(mem.gender) === parseInt(prem.gender)
        );
        const individualBreakdown = {
          info:
            member.name + "\n (" + member.age + ", " + member.genderStr + ")",
          basic: "$" + (prem.inpatient?.amount ?? 0),
          outpatient: "$" + (prem.outpatient?.amount ?? 0),
          maternity: "$" + (prem.maternity?.amount ?? 0),
          dental: "$" + (prem.dental?.amount ?? 0),
        };
        breakdownList.push(individualBreakdown);
      });
      return breakdownList;
    };

    return {
      props,
      format,
      CheckMark,
      rowHeight,
      getId,
      getLabelId,
      isHidden,
      hideRow,
      step,
      staticText,
      discountDialog,
      updateDialog,
      discount,
      expandRow,
      premiums,
      getDiscount,
      openBreakdownDialog,
      getBreakdown,
    };
  },
});
