enum ExportStatic {
  TOPIC = "Medical Plan Comparisons on Coverage and Annual Premiums",
  NOTE = "Note: Currencies in USD$",
  CLIENT = "Client: ",
  DETAILS = "Details",
  DISCLAIMER_1 = "This comparison chart is for information only and without responsibility. Above premiums may not include the Hong Kong mandatory insurance levy of 0.1% as of 1 April 2021.\nThis is only a policy summary, for details kindly refer to the benefit schedule, terms & conditions and exclusions of the policy.",
  DISCLAIMER_2 = "The above plans are individual plans and it is important to note that a medical history may cause higher premium, and in some instances, an exclusion or rejection in the insurance coverage.\nCyber Insurance Brokers Limited is remunerated for its services by the receipt of commission paid by insurers. Your agreement to proceed with this insurance transaction shall constitute your consent to the receipt of commission by the Company.",
  DISCLAIMER_3 = " = full cover (100 %) up to the overall annual limit | - Not covered",
  CONTACT = "Contact Alea for more information - hello@alea.care | Tel  2296 9773 | Unit 2202, 22/F, Seaview Commercial Building, 21-24 Connaught Road West, Sheung Wan, Hong Kong",
  LICENSE = "Alea brokerage services are operated by Cyber Insurance Brokers Ltd., Insurance Broker Company License No. FB1417",
  UNLIMITED = "Unlimited",
  UPTO = "Up to ",
  DOLLAR_SIGN = "$",
  ANNUAL_MAX_LIM = "Annual Maximum Limit in USD$",
  ANNUAL_OUT_MAX_LIM = "Outpatient Subject to Annual Limit",
  DEDUCT_LABEL = "Annual Deductibles Per Person",
  DEDUCT_OPTION = "Deductible Option",
  COPAYMENT_OPTION = "CoPayment Option",
  ANNUAL_PREMIUMS = "Annual Premiums in USD$",
  PAYMENT_SURCHARGE = "Payment surcharge",
  MATERNITY = "Maternity",
  DENTAL = "Dental",
  OPTICAL = "Optical",
  EXCLUDING = " Excluding ",
  QUOTATION_PDF_FILENAME = "Quotation",
  ANNUAL = "Annual",
  MONTHLY = "Monthly",
  NO_SURCHARGE = "No surcharge",
  SURCHARGE_OF = "Surcharge of ",
  OUTPATIENT = "Out-Patient",
  UNAUTHORIZED = "Unauthorized",
  USD = "USD",
}
export default ExportStatic;
