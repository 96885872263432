import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=6712ce56&scoped=true&"
import script from "./Step4.vue?vue&type=script&lang=ts&"
export * from "./Step4.vue?vue&type=script&lang=ts&"
import style0 from "./Step4.vue?vue&type=style&index=0&id=6712ce56&lang=scss&scoped=true&"
import style1 from "./Step4.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6712ce56",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VChip,VCombobox,VContainer,VForm,VSelect,VSwitch,VTextField})
