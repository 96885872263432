























import {
  defineComponent,
  reactive,
  watch,
  ref,
  watchEffect,
} from "@vue/composition-api";

import DealComponent from "../components/Deal.vue";
import { useActions, useGetters } from "vuex-composition-helpers";
import API from "@/plugins/axios";
import pipedriveServices from "@/enumerators/pipedriveServices";
import leadServices from "@/services/lead";
import UserService from "../services/users";
export default defineComponent({
  components: {
    DealComponent,
  },
  props: ["pipedriveFieldInfo", "step"],
  setup(props) {
    const {
      search,
      user,
      clientSelection,
      dealSelection,
      isOrganization,
    } = useGetters([
      "search",
      "user",
      "clientSelection",
      "dealSelection",
      "isOrganization",
    ]);
    const { SET_STEP } = useActions(["SET_STEP"]);
    const deal = reactive({
      lead: [],
      deal: [],
    });
    let loading = ref(false);
    loading.value = true;
    watch(dealSelection, () => SET_STEP(4));
    watchEffect(async () => {
      const params = {
        api_token: process.env.VUE_APP_PIPEDRIVE_TOKEN,
        term: isOrganization.value
          ? clientSelection.value.Organization?.name
          : search.value.value,
        search_for_related_items: "true",
      };
      let itemResult: any = await API.pipedrive().get(
        pipedriveServices.ITEM_SEARCH + new URLSearchParams(params)
      );
      // ?.data.data.related_items;

      if (itemResult) {
        let result = [
          ...itemResult.data.data.items,
          ...itemResult.data.data.related_items,
        ];
        if (!user.value.admin) {
          result = UserService.getOwnerData(user.value.pipedriveId, result);
        }

        const leadFilter = leadServices.filterLead(
          result,
          clientSelection.value.id,
          clientSelection.value.Organization?.value
        );

        const dealFilter = leadServices.filterOpenDeal(
          result,
          clientSelection.value.id,
          clientSelection.value.Organization?.value
        );
        deal.lead = await leadServices.createListLeadDeal(
          leadFilter,
          pipedriveServices.LEADS,
          props.pipedriveFieldInfo.currencyExchange
        );
        deal.deal = await leadServices.createListLeadDeal(
          dealFilter,
          pipedriveServices.DEALS,
          props.pipedriveFieldInfo.currencyExchange
        );
      }

      loading.value = false;
    });
    return { deal, props, loading };
  },
});
