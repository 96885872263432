var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"card"}},[(_vm.props.label == 'Remove')?_c('div',{staticClass:"quotation-cell remove-row",class:[
      _vm.props.color,
      {
        bold: _vm.props.bold,
        quotationSearch: _vm.props.isSearch,
      } ],style:({ height: _vm.rowHeight + 'px' })},[_c('div',{staticClass:"remove-label benefit-label bold black-text",class:[_vm.props.color, { 'pink-bg': _vm.props.color.length === 0 }],attrs:{"id":_vm.getLabelId}},[_vm._v(" Total number of policies: "+_vm._s(_vm.props.plans.length)+" ")]),_vm._l((_vm.props.plans),function(col,index){return _c('div',{key:index,staticClass:"benefit-col remove-col",class:[_vm.props.color, { 'white-bg': _vm.props.color.length === 0 }],attrs:{"id":_vm.getId}},[_c('v-btn',{staticClass:"removeBtn",attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.props.removeCol(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1)],1)})],2):(_vm.props.benefit)?_c('div',{staticClass:"quotation-cell",class:[
      _vm.props.color,
      {
        bold: _vm.props.bold,
        'pink-bg': _vm.props.color.length === 0,
        quotationSearch: _vm.props.isSearch,
        hideRow: _vm.isHidden,
      } ],style:({ height: _vm.rowHeight + 'px' })},[_c('div',{staticClass:"benefit-label bold",class:[_vm.props.color, { 'pink-bg': _vm.props.color.length === 0 }],staticStyle:{"font-family":"OpenSans","font-weight":"bold"},attrs:{"id":_vm.getLabelId}},[(_vm.step == 5)?_c('v-icon',{attrs:{"id":"hideEye","size":"small"},on:{"click":function($event){return _vm.hideRow(_vm.props.label)}}},[_vm._v("mdi-eye-off-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.props.label)+" ")],1),_vm._l((_vm.props.plans),function(col,index){return _c('div',{key:index,staticClass:"benefit-col",class:[_vm.props.color, { 'white-bg': _vm.props.color.length === 0 }],staticStyle:{"font-family":"OpenSans"},attrs:{"id":_vm.getId}},[(!col.benefit)?_c('span',{staticClass:"cell-content"},[_vm._v("-")]):_c('span',{staticClass:"cell-content"},[_c('CheckMark'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.format.interpretLimit(col.benefit.limit, col.currency))}})],1)])})],2):(
      !(
        (_vm.props.label.trim() == 'CoPayment Option' ||
          _vm.props.label.trim() == 'Deductible Option') &&
        _vm.format.isEmptyRow(_vm.props.plans, null)
      )
    )?_c('div',{staticClass:"quotation-cell",class:[
      _vm.props.color,
      {
        bold: _vm.props.bold,
        quotationSearch: _vm.props.isSearch,
        hideRow: _vm.isHidden,
      } ],style:({ height: _vm.rowHeight + 'px' })},[_c('div',{staticClass:"benefit-label",class:[_vm.props.color, { 'pink-bg': _vm.color.length == 0 }],staticStyle:{"font-family":"OpenSans","font-weight":"bold"},attrs:{"id":_vm.getLabelId}},[(_vm.step == 5)?_c('v-icon',{staticClass:"hideEye",attrs:{"id":"hideEye","size":"small"},on:{"click":function($event){return _vm.hideRow(_vm.props.label)}}},[_vm._v("mdi-eye-off-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.props.label)+" ")],1),_vm._l((_vm.props.plans),function(col,index){return _c('div',{key:index,staticClass:"benefit-col",class:[_vm.props.color],staticStyle:{"font-family":"OpenSans","font-weight":"bold"},attrs:{"id":_vm.getId}},[_c('transition',{attrs:{"name":"card"}},[(col == true)?_c('span',{staticClass:"cell-content"},[_c('CheckMark')],1):(col == false)?_c('span',{staticClass:"cell-content"},[_vm._v("-")]):(_vm.props.label == _vm.staticText.ANNUAL_PREMIUMS)?_c('span',[(
              _vm.format.getDiscount(_vm.getDiscount(col.policyID)) &&
                _vm.getDiscount(col.policyID).displayOriginal
            )?_c('span',{staticClass:"originalPrice"},[_vm._v(_vm._s(_vm.staticText.DOLLAR_SIGN + col.annualPremium)+" "),_c('br')]):_vm._e(),(_vm.premiums[col.policyID].origPremium !== null)?_c('span',{class:[
              {
                'discount-price':
                  _vm.format.getDiscount(_vm.getDiscount(col.policyID)) &&
                  _vm.getDiscount(col.policyID).displayOriginal,
              },
              { premium: _vm.step == 5 },
              { finalPremium: _vm.step == 5 && col.family } ],on:{"click":function($event){return _vm.openBreakdownDialog(col.policyID)}}},[_vm._v(" "+_vm._s(_vm.format.handleDiscount(col, _vm.getDiscount(col.policyID)))+" ")]):_vm._e(),(
              _vm.premiums[col.policyID].origPremium === null &&
                _vm.premiums[col.policyID].premium !== null
            )?_c('span',{class:[{ premium: _vm.step == 5 }]},[_vm._v(" $"+_vm._s(_vm.premiums[col.policyID].premium.value)+" ")]):_vm._e(),(_vm.step == 5 && col.family)?_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.premiums[col.policyID].breakdownDialog),callback:function ($$v) {_vm.$set(_vm.premiums[col.policyID], "breakdownDialog", $$v)},expression:"premiums[col.policyID].breakdownDialog"}},[(_vm.premiums[col.policyID].breakdownDialog)?_c('PremiumBreakdown',{attrs:{"breakdownList":_vm.getBreakdown(col.family),"premium":col,"discount":_vm.getDiscount(col.policyID)}}):_vm._e()],1):_vm._e(),(_vm.step == 5 && _vm.premiums[col.policyID].origPremium === null)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[[_c('v-icon',{staticClass:"editIcon",attrs:{"color":"black"}},[_vm._v(" mdi-pencil ")])]],2)]}}],null,true),model:{value:(_vm.premiums[col.policyID].premiumDialog),callback:function ($$v) {_vm.$set(_vm.premiums[col.policyID], "premiumDialog", $$v)},expression:"premiums[col.policyID].premiumDialog"}},[_c('NewPremium',{attrs:{"premiumDetails":_vm.premiums[col.policyID],"updateDialog":_vm.updateDialog}})],1):_vm._e(),(_vm.step == 5 && _vm.premiums[col.policyID].origPremium !== null)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('PriceDropIcon')],1)]}}],null,true),model:{value:(_vm.premiums[col.policyID].discountDialog),callback:function ($$v) {_vm.$set(_vm.premiums[col.policyID], "discountDialog", $$v)},expression:"premiums[col.policyID].discountDialog"}},[_c('Discount',{attrs:{"premiumDetails":_vm.premiums[col.policyID],"updateDialog":_vm.updateDialog,"expandRow":_vm.expandRow}})],1):_vm._e()],1):_c('span',{staticClass:"cell-content"},[_vm._v(_vm._s(col))])])],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }