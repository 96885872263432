



















































































































































import {
  defineComponent,
  reactive,
  onMounted,
  computed,
  ref,
} from "@vue/composition-api";
import { useActions, useGetters } from "vuex-composition-helpers";
import CompareCell from "../components/CompareCell.vue";
import Benefits from "../components/Benefits.vue";
import Progression from "../components/Progression.vue";
import API from "@/plugins/axios";
import Catalogue from "@/enumerators/catalogue";
import staticText from "../enumerators/export";
import QuotationCell from "@/components/QuotationCell.vue";
import QuotationHeader from "@/components/QuotationHeader.vue";
import format from "../services/format";
import VueHtml2pdf from "vue-html2pdf";
import router from "@/router";
import CheckMark from "../components/Checkmark.vue";
import Logo from "../components/Logo.vue";
import session from "@/services/session";

export default defineComponent({
  props: ["id"],
  components: {
    CompareCell,
    Benefits,
    QuotationCell,
    QuotationHeader,
    VueHtml2pdf,
    Progression,
    CheckMark,
    Logo,
  },
  setup(props) {
    const {
      SET_QUOTATION,
      SET_STEP,
      SET_DISCOUNT,
      SET_HIDDENROWS,
      SET_PREMIUMS,
    } = useActions([
      "SET_QUOTATION",
      "SET_STEP",
      "SET_DISCOUNT",
      "SET_HIDDENROWS",
      "SET_PREMIUMS",
    ]);
    const {
      quotation,
      step,
      user,
      searchSelection,
      benefitsNames,
      discount,
      hiddenRows,
      premiums,
    } = useGetters([
      "quotation",
      "step",
      "user",
      "searchSelection",
      "benefitsNames",
      "discount",
      "hiddenRows",
      "premiums",
    ]);
    const state = reactive({
      benefitsName: {
        inPatient: [],
        outPatient: [],
      },
      policy: {
        data: [],
      },
    });
    const clientInfo = ref([]);
    const notFound = ref(false);
    const errorStatus = ref("Not Found");
    onMounted(async () => {
      const benef = benefitsNames.value.length
        ? benefitsNames.value
        : await format.getBenefitsName();
      state.benefitsName.inPatient = format.sortByOrder(
        format.filterByType(benef.data, Catalogue.PatientType.IN_PATIENT)
      );

      state.benefitsName.outPatient = format.sortByOrder(
        format.filterByType(benef.data, Catalogue.PatientType.OUT_PATIENT)
      );

      if (props?.id) {
        const policyRes = await API.strapi(session.getStoredToken())
          .get(
            "quotation-numbers/history?quotationID=" +
              props?.id +
              "&pipedriveID=" +
              user.value.pipedriveId +
              "&isAdmin=" +
              user.value.admin
          )
          .catch((err) => {
            console.log(err);
            if (err.response.statusText === staticText.UNAUTHORIZED)
              session.logout();
          });
        if (policyRes && policyRes.data?.status) {
          state.policy.data = policyRes.data.data.previewResult;

          SET_QUOTATION({
            id: props?.id,
            res: policyRes.data.data.previewResult,
          });
          if (policyRes.data.data.premiums) {
            //console.log("policyRes.data.data.premiums: ",policyRes.data.data.premiums)
            SET_PREMIUMS(policyRes.data.data.premiums);
          }
          if (policyRes.data.data.hiddenBenefits) {
            const hiddenRowObj = policyRes.data.data.hiddenBenefits;
            SET_HIDDENROWS(hiddenRowObj);
          }
          if (policyRes.data.data.client) {
            clientInfo.value = [...policyRes.data.data.client];
          }
        } else {
          if (policyRes) errorStatus.value = policyRes.data?.data;
          notFound.value = true;
        }
      } else {
        const storeQuotation = JSON.parse(JSON.stringify(quotation.value));
        state.policy.data = storeQuotation.res;

        clientInfo.value = searchSelection.value.isOrganization
          ? searchSelection.value.organizationMembers
          : [
              {
                name: searchSelection.value.name,
                age: searchSelection.value.age,
              },
            ];
      }
      handleInvalidId();

      if (state.policy.data?.length > 0) {
        format.updateQuotation(
          "preview",
          quotation,
          premiums.value,
          hiddenRows.value
        );
      }
    });

    const handleInvalidId = () => {
      if (!state.policy.data?.length) {
        setTimeout(() => {
          SET_STEP(1);
          if (window.history.length > 1) router.go(-1);
        }, 2000);
      }
    };

    const getClientDetails = (clientList) => {
      return clientList
        .map((client) => {
          return client.name + ", " + client.age;
        })
        .join(" | ");
    };

    const plans = computed(() => format.getHeaderPlans(state.policy.data));

    const planDetails = computed(() =>
      format.getHeaderPlansDetails(state.policy.data)
    );

    const annualLimit = computed(() =>
      format.interpretAnnualLimit(state.policy.data)
    );
    const deductOption = computed(() =>
      format.interpretDeductOption(state.policy.data)
    );
    const coPayOption = computed(() =>
      format.interpretCopayOption(state.policy.data)
    );
    const annualDeduct = computed(() =>
      format.interpretAnnualDeduct(state.policy.data)
    );
    const annualPremium = computed(() =>
      format.interpretAnnualPremium(state.policy.data)
    );
    const paymentSurcharge = computed(() =>
      format.interpretSurcharge(state.policy.data)
    );

    const includeInPlan = (benefitsId: number, benefitslist: []) =>
      benefitslist.find(
        (b: any) => b.benefits_name.id == benefitsId && b.include
      );
    const coverage_include = format.coverageInclude(state.policy.data);

    const coverage_exclude = format.coverageExclude(state.policy.data);

    const goBack = () => {
      router.go(-1);
    };

    return {
      state,
      includeInPlan,
      coverage_include,
      coverage_exclude,
      plans,
      planDetails,
      staticText,
      annualLimit,
      goBack,
      step,
      notFound,
      format,
      errorStatus,
      deductOption,
      coPayOption,
      annualDeduct,
      annualPremium,
      paymentSurcharge,
      getClientDetails,
      quotation,
      clientInfo,
    };
  },
});
