import Vue from "vue";
import Vuex from "vuex";
import User from "@/dto/User";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: new User(),
    search: {
      field: "",
      value: "",
    },
    step: 1,
    clientSelection: {},
    dealSelection: {},
    searchSelection: {},
    benefitsNames: {},
    area: [],
    mixOptions: [],
    quotation: {},
    hiddenRows: {
      labels: [],
    },
    discount: {
      amount: "",
      percent: "",
    },
    premiums: {},
    createQuotation: true,
    isOrganization: false,
  },
  getters: {
    user: (state: any) => {
      return state.user;
    },
    search: (state: any) => {
      return state.search;
    },
    step: (state: any) => {
      return state.step;
    },
    clientSelection: (state) => {
      return state.clientSelection;
    },
    dealSelection: (state) => {
      return state.dealSelection;
    },
    searchSelection: (state) => {
      return state.searchSelection;
    },
    benefitsNames: (state) => {
      return state.benefitsNames;
    },
    area: (state) => {
      return state.area;
    },
    mixOptions: (state) => {
      return state.mixOptions;
    },
    quotation: (state) => {
      return state.quotation;
    },
    hiddenRows: (state) => {
      return state.hiddenRows;
    },
    discount: (state) => {
      return state.discount;
    },
    premiums: (state) => {
      return state.premiums;
    },
    createQuotation: (state) => {
      return state.createQuotation;
    },
    isOrganization: (state) => {
      return state.isOrganization;
    },
  },
  mutations: {
    MUTATE_USER(state, user: User) {
      state.user = user;
    },
    MUTATE_SEARCH(state, search) {
      state.search = search;
    },
    MUTATE_STEP(state, step: number) {
      state.step = step;
    },
    MUTATE_CLIENTSELECTION(state, clientSelection) {
      state.clientSelection = clientSelection;
    },
    MUTATE_DEAL_SELECTION(state, dealSelection) {
      state.dealSelection = dealSelection;
    },
    MUTATE_SEARCH_SELECTION(state, searchSelection) {
      state.searchSelection = searchSelection;
    },
    MUTATE_AREA(state, area) {
      state.area = area;
    },
    MUTATE_MIX_OPTIONS(state, mixOptions) {
      state.mixOptions = mixOptions;
    },
    MUTATE_BENEFITS_NAMES(state, benefitsNames) {
      state.benefitsNames = benefitsNames;
    },
    MUTATE_QUOTATION(state, quotation) {
      state.quotation = quotation;
    },
    MUTATE_DISCOUNT(state, discount) {
      state.discount = discount;
    },
    MUTATE_HIDDENROWS(state, hiddenRows) {
      if (!state.hiddenRows.labels.length) state.hiddenRows = hiddenRows;
      Vue.set(state, "hiddenRows.labels", state.hiddenRows.labels);
    },
    MUTATE_PREMIUMS(state, premiums) {
      state.premiums = premiums;
    },
    MUTATE_CREATE_QUOTATION(state, createQuotation) {
      state.createQuotation = createQuotation;
    },
    MUTATE_IS_ORGANIZATION(state, isOrganization) {
      state.isOrganization = isOrganization;
    },
  },
  actions: {
    SET_USER({ commit }, user: User) {
      commit("MUTATE_USER", user);
    },
    SET_SEARCH({ commit }, search) {
      commit("MUTATE_SEARCH", search);
    },
    SET_STEP({ commit }, step: number) {
      commit("MUTATE_STEP", step);
    },
    SET_CLIENTSELECTION({ commit }, clientSelection) {
      commit("MUTATE_CLIENTSELECTION", clientSelection);
    },
    SET_DEALSELECTION({ commit }, dealSelection) {
      commit("MUTATE_DEAL_SELECTION", dealSelection);
    },
    SET_SEARCHSELECTION({ commit }, searchSelection) {
      commit("MUTATE_SEARCH_SELECTION", searchSelection);
    },
    SET_BENEFITS_NAMES({ commit }, benefitsNames) {
      commit("MUTATE_BENEFITS_NAMES", benefitsNames);
    },
    SET_AREA({ commit }, area) {
      commit("MUTATE_AREA", area);
    },
    SET_MIX_OPTIONS({ commit }, mixOptions) {
      commit("MUTATE_MIX_OPTIONS", mixOptions);
    },
    SET_QUOTATION({ commit }, quotation) {
      commit("MUTATE_QUOTATION", quotation);
    },
    SET_DISCOUNT({ commit }, discount) {
      commit("MUTATE_DISCOUNT", discount);
    },
    SET_HIDDENROWS({ commit }, hiddenRows) {
      commit("MUTATE_HIDDENROWS", hiddenRows);
    },
    SET_PREMIUMS({ commit }, premiums) {
      commit("MUTATE_PREMIUMS", premiums);
    },
    SET_CREATE_QUOTATION({ commit }, createQuotation) {
      commit("MUTATE_CREATE_QUOTATION", createQuotation);
    },
    SET_IS_ORGANIZATION({ commit }, isOrganization) {
      commit("MUTATE_IS_ORGANIZATION", isOrganization);
    },
    CLEAR_SEARCHSELECTION({ commit }) {
      commit("MUTATE_SEARCH_SELECTION", {});
    },
  },
  modules: {},
});
